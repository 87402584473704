import React from 'react'
import { graphql } from 'gatsby'
import { injectIntl } from 'react-intl'
import { withIntl } from 'i18n'

import Layout from '../components/Layout'
import SEO from '../components/Layout/SEO'
import Hero from '../components/Hero'
import ContentBlock from '../components/ContentBlock'
import LocationContent from '../components/Location/LocationContent'
import LocationMap from '../components/Location/LocationMap'

const LocationPage = ({ data, intl }) => {
  return (
    <Layout>
      <SEO
        title={intl.formatMessage({ id: 'location' })}
        description={intl.formatMessage({ id: 'description.location' })}
      />

      <Hero
        className="location"
        title={intl.formatMessage({ id: 'location.title' })}
        image={data.image}
        quote={{
          text: intl.formatMessage({ id: 'location.quote' }),
          author: intl.formatMessage({ id: 'location.quote.author' }),
        }}
      />

      <ContentBlock
        title={intl.formatMessage({ id: 'location' })}
        className="location"
      >
        <LocationContent />
      </ContentBlock>

      <ContentBlock>
        <LocationMap />
      </ContentBlock>
    </Layout>
  )
}

export default withIntl(injectIntl(LocationPage))

export const LocationQuery = graphql`
  query {
    image: file(relativePath: { eq: "location-hero.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
