import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'

import './location-map.scss'

const LocationMap = () => (
  <StaticQuery
    query={graphql`
      query {
        image: file(relativePath: { eq: "brussels-map.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 800) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `}
    render={data => {
      return (
        <div className="location-map">
          <div className="container">
            <div id="map" className="map">
              <Img fluid={data.image.childImageSharp.fluid} />
            </div>
          </div>
        </div>
      )
    }}
  />
)

export default LocationMap
